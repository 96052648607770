<template>
    <div class="container max-w-screen-xl mx-auto">
        <v-overlay :value="overlayLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        
    <!-- CONTENT header Start -->  
        <v-card elevation="6" color="blue-grey lighten-4">      
            <span class="col-span-11 text-sm text-gray-600 font-italic my-1 mx-2">
            Add archived text
            </span>
        </v-card>
    <!-- CONTENT header End -->  

    <!-- CONTENT articleTitle, authorNames Start -->
        <v-card class="overflow-hidden" color="blue-grey lighten-3" dark>
            <v-toolbar flat color="#333366">
                <v-icon>mdi-book-plus-multiple</v-icon>
                    <v-toolbar-title class="mx-2 font-weight-light">Add article</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="blue-grey darken-2" fab small @click="isEditing = !isEditing">
                <v-icon v-if="isEditing">mdi-close</v-icon>
                <v-icon v-else>mdi-pencil</v-icon>
            </v-btn>
            </v-toolbar>
            
            <v-card color="#333366" class="mt-4">
                <div class="m-2 px-2">
                    <div class="grid grid-cols-1 md:grid-cols-7 gap-x-12 mt-5">
                        <v-text-field class="col-span-1"
                            prepend-inner-icon="mdi-book-open-page-variant-outline"
                            outlined
                            dense
                            :disabled="!isEditing" 
                            v-model="romanStyle"
                            filled   
                            clearable                    
                            label="Volume">
                        </v-text-field>
                        <v-select class="col-span-2" v-model="articleYear" :items="years" dense outlined filled label="Published year"></v-select>
                        <v-text-field
                        class="col-span-2"
                            prepend-inner-icon="mdi-contain-start"
                            type="number" 
                            outlined
                            dense                        
                            :disabled="!isEditing" 
                            v-model="pageStart"
                            filled    
                            clearable                    
                            label="Page From:">
                        </v-text-field>
                        <v-text-field
                        class="col-span-2"
                            prepend-inner-icon="mdi-contain-end"
                            type="number" 
                            outlined
                            dense
                            :disabled="!isEditing" 
                            v-model="pageEnd"
                            filled    
                            clearable                    
                            label="Page To:">
                        </v-text-field>
                    </div>
                    <v-text-field
                        prepend-inner-icon="mdi-subtitles"
                        dense
                        outlined
                        :disabled="!isEditing" 
                        v-model="articleTitle"
                        filled    
                        clearable                    
                        label="Article Title">
                    </v-text-field>
                </div>
                <v-form ref="formAuthor">
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-x-12 mx-2 px-2 -mt-2">
                        <v-text-field 
                            outlined
                            dense
                            prepend-inner-icon="mdi-code-parentheses"
                            :disabled="!isEditing" 
                            v-model="authorTitle"
                            filled    
                            clearable                    
                            label="Academic Title">
                        </v-text-field>

                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-account-edit-outline"
                            v-model="authorFirstName"
                            :disabled="!isEditing"
                            :rules="rules.authorFirstName"
                            required                        
                            filled 
                            clearable                       
                            label="First Name">
                        </v-text-field>

                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-account-edit-outline"
                            v-model="authorFamilyName"
                            :disabled="!isEditing"
                            :rules="rules.authorFamilyName"
                            required
                            filled    
                            clearable                    
                            label="Family Name">
                        </v-text-field>
                    </div>
                </v-form>
            </v-card>                
            <v-divider></v-divider>
            <div>
                <v-chip
                    :disabled="!isEditing"
                    label
                    large 
                    class="m-b-6 m-2"
                    color="#2a6ebb"
                    text-color="white" v-for="author in authorNames" :key="author.id">
                    
                    <v-avatar left>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                        {{author.authorTitle}}
                        {{author.authorFirstName}}
                        {{author.authorFamilyName}}
                    <v-avatar right @click="removeAuthorName(author)">
                        <v-icon>mdi-close</v-icon>
                    </v-avatar>
                </v-chip>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn :disabled="!validAuthorName" v-if="isEditing" color="orange darken-2" @click="addAuthorName()">
                    Add Author Name
                    <v-icon dark right>mdi-plus-circle-outline</v-icon>
                </v-btn>
            <!-- <v-btn :disabled="this.authorNames.length <= 0" color="success" @click="isEditing = false; hasSavedAuthors = true;">
                    Продължи                
                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                </v-btn>
                -->
            </v-card-actions>
            <!-- <v-snackbar  
                v-model="hasSavedAuthors"
                :timeout="4000"
                absolute
                color="success"
                bottom
                right>
            <div class="text-center text-lg">
                <span>Имената на авторите са съхранени</span>
                <v-icon dark right>mdi-thumb-up</v-icon>
            </div>
            </v-snackbar>
            -->
            <v-snackbar  
                v-model="emptyAuthorNames"
                :timeout="4000"
                absolute
                color="warning"
                bottom
                right>
            <div class="text-center text-lg">
                <span>Please fill in Author's First name and Family</span>
                <v-icon dark right>mdi-thumb--down</v-icon>
            </div>
            </v-snackbar>
        </v-card>
    <!-- CONTENT articleTitle, authorNames End -->

    <!-- CONTENT article summary and keywords Start -->
        <v-card class="overflow-hidden" color="blue-grey lighten-3" dark>
                    
            <v-card color="#333366">
                <div class="grid grid-cols-1 gap-x-12 mx-2 px-2 mt-6">
                    <v-textarea
                        :disabled="!isEditing" 
                        dense
                        auto-grow 
                        prepend-inner-icon="mdi-format-text"
                        outlined
                        v-model="articleSummary"
                        filled    
                        clearable   
                        counter                 
                        label="Article summary">
                    </v-textarea>
                <v-form ref="formKeyword">
                    <v-text-field
                            dense
                        prepend-inner-icon="mdi-script-text-key-outline"
                        v-model="keyword"
                        :disabled="!isEditing"
                        outlined
                        :rules="rules.keyword"
                        required
                        filled    
                        clearable                    
                        label="Keyword">
                    </v-text-field>
                </v-form>
                </div>
            </v-card>                
            <v-divider></v-divider>
            <div>
                <v-chip
                    :disabled="!isEditing"
                    label
                    large 
                    class="m-b-6 m-2"
                    color="#2a6ebb"
                    text-color="white" v-for="keyword in keywords" :key="keyword.id">                
                    <v-avatar left>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                        {{keyword.keyword}}
                    <v-avatar right @click="removeKeyword(keyword)">
                        <v-icon>mdi-close</v-icon>
                    </v-avatar>
                </v-chip>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                
                <v-btn :disabled="!keyword" v-if="isEditing" color="orange darken-2" @click="addKeyword()">
                    Add Keyword
                    <v-icon dark right>mdi-plus-circle-outline</v-icon>
                </v-btn>
                <v-btn :disabled="((this.authorNames.length <= 0) || (this.keywords.length <= 0))" 
                        color="success" 
                        @click="isEditing = false; hasSavedAuthors = true; save();">
                    Save                
                    <v-icon dark right>mdi-checkbox-marked-circle</v-icon>
                </v-btn>
            </v-card-actions>
            <v-snackbar  
                v-model="hasSavedAuthors"
                :timeout="4000"
                absolute
                color="success"
                bottom
                right>
            <div class="text-center text-lg">
                <span>Article is saved</span>
                <v-icon dark right>mdi-thumb-up</v-icon>
            </div>
            </v-snackbar>
            <v-snackbar  
                v-model="emptyAuthorNames"
                :timeout="4000"
                absolute
                color="warning"
                bottom
                right>
            <div class="text-center text-lg">
                <span>Моля попълнете Име и Фамилия на автор</span>
                <v-icon dark right>mdi-thumb--down</v-icon>
            </div>
            </v-snackbar>
        </v-card>
    <!-- CONTENT article summary and keywords End -->

    </div>
</template>
<script>
import axios from 'axios'; 

export default {
    data () {
      return { 
        overlayLoading: false,
        years: ['2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021','2022', '2023', '2024', '2025'],
        romanStyle:'',
        articleYear:'',
        pageStart:'',
        pageEnd:'',
        articleTitle:'',
        authorTitle:'',
        authorFirstName:'',
        authorFamilyName:'',
        author: [], 
        authorNames:[],//[{authorTitle: 'инж.', authorFirstName: 'Д.', authorFamilyName: 'Петков'}],
        articleSummary:'',
        keyword:'',
        keywords:[],
        hasSavedAuthors: false,
        emptyAuthorNames: false,
        isEditing: true,
        rules: {          
          authorFirstName: [val => (val || '').length > 0 || 'This field is required'],
          authorFamilyName: [val => (val || '').length > 0 || 'This field is required'],
          keyword: [val => (val || '').length > 0 || 'This field is required'],
        },
      }
    },
    methods: {
      addAuthorName() {        
        var idx = this.authorNames.length; //use as Array index
        this.authorNames.push({id: idx, authorTitle: this.authorTitle || '', authorFirstName: this.authorFirstName, authorFamilyName: this.authorFamilyName });
        this.clearAuthorNameFields();
      },
      removeAuthorName(author) {        
        var idx =  this.authorNames.findIndex(x => x.id === author.id);
        this.authorNames.splice(idx, 1);
      },
      clearAuthorNameFields(){
        this.$refs.formAuthor.reset();
      },      
      addKeyword() {   
        this.hasSavedKeywords = true;
        var idx = this.keywords.length; //use as Array index
        this.keywords.push({id: idx, keyword: this.keyword});
        this.clearKeywordField();
      },
      removeKeyword(keyword) {        
        var idx =  this.keywords.findIndex(x => x.id === keyword.id);
        this.keywords.splice(idx, 1);
      },
      clearKeywordField(){
        this.$refs.formKeyword.reset();
      },
      save () {
        //this.isEditing = !this.isEditing
        //this.hasSaved = true
        var articleSummary =  [{romanStyle: this.romanStyle , 
                                articleYear: this.articleYear,
                                pageStart: this.pageStart,
                                pageEnd: this.pageEnd,
                                articleTitle: this.articleTitle,
                                articleSummary: this.articleSummary}];

        var finishedArticle = [];
        finishedArticle.push(articleSummary);
        finishedArticle.push(this.authorNames);
        finishedArticle.push(this.keywords);
        console.log(finishedArticle);

        //save result and reload page!
        axios.post('/archive/savearticle', finishedArticle)
              .then(res => {
              //console.log(res);
              if (res.status === 200) {
                  this.overlayLoading = true;

                  setTimeout(() => {
                      this.clearFormAfterSave();
                      this.overlayLoading = false;
                      this.isEditing = true;
                  }, 1200);
                }
              })
              .catch(error => console.log(error))
      },    
      clearFormAfterSave() {
        this.romanStyle = 'LI';
        this.articleYear = '2012';
        this.pageStart = '';
        this.pageEnd = '';
        this.articleTitle = '';
        this.articleSummary = '';
        this.authorNames = [];
        this.keywords = [];
      },  
    },
    computed: {
      validAuthorName: function () {
          if (this.authorFirstName && this.authorFamilyName){
              return true;
          }
          return false;           
      },
    },
    mounted(){ 
      //this.emptyAuthorNames = true;
    }
  }
</script>